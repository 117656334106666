import { Center, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"

export default function Error() {
  return (
    <Center flex={1} h="100vh" flexDir={"column"}>
      <Heading as="h1">Oopps... 404 Error</Heading>
      <Link to="/">Click to Main Page</Link>
    </Center>
  )
}
